import React from 'react'
import { graphql, Link } from 'gatsby'

// import ScrollToTop from '../components/ScrollToTop';


//import react-reveal
import Fade from 'react-reveal/Fade';


//import custom components
import Head from '../components/layouts/head'
import PageLayout from '../components/layouts/PagesLayout'
import FeaturedPosts from '../components/FeaturedPosts'


class HomePage extends React.Component
{

  smoothMouve(event){
    event.preventDefault();

    return window.scrollTo({
              top:800,
              behavior:'smooth'
          });
  }



  render()
  {

    const { data } = this.props;
  

    return (
      <PageLayout>
          <section className="page-404">
            <div className="page-error-content">
                <h1>error</h1>
                <span>404</span>
            </div>
        </section>
      </PageLayout>
    )
  }
}

export default HomePage;



export const query = graphql
  `
  query indexQuery {
    allMarkdownRemark (limit: 3, sort: {order: ASC, fields: id}){
      edges {
        node {
          id
          frontmatter {
            date
            title
            featuredImage {
              childImageSharp {
                id
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                  tracedSVG
                  originalImg
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
          excerpt(
            format: PLAIN
            pruneLength:80
            truncate: true
            )
        }
      }
    }
  }
`
